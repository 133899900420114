@use "../../variables" as v;

#home-container{
  height: 100%;
  background-size: cover;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: v.$theme-gold;

  #light-container{
    position: absolute;
    top: -80px;
  }

  .nft-id{
    font-size: 14px;
  }

  // #swiper-container{
  //   width: 100%;
  //   height: 250px;
  // }

  #platform{
    width: 100vw;
    height: 115px;
    position: absolute;
    bottom: 156px;

    img{
      width: 100%;
      height: 100%;
    }
  }

  #retrieve-button{
    //position: absolute;
    //bottom: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 185px;
    height: 39px;
    color: white;
    background: linear-gradient(180deg, #0085FE 0%, rgba(0, 133, 254, 0.74) 100%);
    box-shadow: 0px 7px 8px rgba(34, 149, 254, 0.12);
    border-radius: 31px;
    z-index: 5;
    margin-top: 470px;
    margin-bottom: 50px;
  }

  .duty-container {
    position: absolute;
    bottom: 10px;
  }
}