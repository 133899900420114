@use "../../variables" as v;

#list-container{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;

  #content-container{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-top: 90px;
    height: 78vh;
    width: 80vw;
    align-content: flex-start;
    justify-content: space-between;
    //margin-left: 20px;
    //margin-right: 20px;
    a{
      text-decoration: none;
    }
    a:visited{
      text-decoration: none;
    }
  }

  .duty-container {
    position: absolute;
    bottom: 22px;
  }
}