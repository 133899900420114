@use "../../variables" as v;

#claim-container{
  background: white;
  display: flex;
  justify-content: center;
  position: relative;
  background-size: cover;
  height: 100vh;
  z-index: 3;
  overflow-y: hidden;

  #claim-content{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 106px;

    #code-input{
      width: 177px;
      height: 40px;
      margin-bottom: 16px;
      border: 1px solid #ADADAD;
      box-sizing: border-box;
      border-radius: 28px;
      margin-top: 20px;

      input{
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 35px;
        background: none;
        //border: 1px solid rgba(255,255,255,0.4);
        color: black;
        padding: 0;
        font-size: 14px;
        box-sizing: border-box;
        // 移除input获取焦点时的效果
        background: none;
        border: none;
        outline: none;
        // 获取焦点时隐藏提示文字？？
        

        &::placeholder{
          color: #CDCDCD;
        }
      }
    }

    #claim-button{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 177px;
      font-size: 14px;
      height: 40px;
      font-weight: 500;
      background: linear-gradient(180deg, #0085FE 0%, rgba(0, 133, 254, 0.74) 100%);
      box-shadow: 0px 7px 8px rgba(34, 149, 254, 0.12);
      border-radius: 28px;
      color: v.$theme-white;
    }

  }

  .duty-free{
    //position: absolute;
    margin-top: 65px;
    position: relative;
  }



}