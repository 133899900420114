@use "../../variables" as v;

#detail-container {
  position: relative;
  background-size: cover;
  z-index: 4;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-style: normal;
  min-height: 100vh;
  background-image: url("../../assets/background.png");

  * {
    box-sizing: border-box;
  }

  .title-container {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 240px;
    height: 60px;

    .home-title {
      position: absolute;
      top: 0px;
      z-index: 2;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      width: 168px;

      font-size: 20px;
      font-weight: 500;
      align-items: center;
      color: #ffffff;

      //.plant-container {
      //  width: 17px;
      //  height: 22px;
      //}
    }

    .background-title {
      position: absolute;
      top: 13px;
      z-index: 1;

      width: 240px;

      font-family: Roboto;
      font-weight: 900;
      font-size: 20px;
      line-height: 18px;

      text-align: center;
      letter-spacing: 0.06em;
      text-transform: uppercase;

      -webkit-text-stroke: 0.2px rgba(255, 255, 255, 0.5);
    }
  }

  .generate-poster {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    width: 343px;
    height: 40px;

    background: #2A7DF9;
    border-radius: 30px;
    color: white;
    margin: 21px  0 34px 0;
  }

  .bottom-background {
    // position: absolute;
    z-index: -1;
    bottom: 0px;
    width: 100%;
    height: 140px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
