
.home-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .home-title{
    display: flex;
    //align-items: center;
    font-size: 22px;
    font-weight: 500;
    z-index: 3;
    height: 70px;
  }

  .plant-container {
    width: 17px;
    height: 22px;
    margin-top: 5px;
  }

  .sub-title {
    z-index: 2;
    width: 240px;
    color: #c6c6c6;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    justify-content: center;
  }

  .sub-title-light {
    z-index: 2;
    width: 132px;
    color: #c6c6c6;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.18) 41.68%, rgba(255, 255, 255, 0) 87.5%);
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    justify-content: center;
  }
}