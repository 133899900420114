.agreement {

  .agreement-content {
    padding: 0 16px 40px;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    color: #101010;
  }
  .about-title {
    height: 65px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.3px;
    color: #101010;
  }
}

